<template>
    <front-layout>
        <v-img :src="require('../../../assets/img/project-back.jpg')" alt="Background image">
            <v-container class="overflow-hidden">
                <v-row>
                    <v-col
                        class="developer-first-row d-flex justify-space-between align-center align-lg-start flex-wrap min-width100 mt-6">
                        <!--                        <a href="tel:+78124139814"
                           class="developer-first-row__phone text&#45;&#45;white font-weight-bold text-decoration-none fz-24">+7
                            (812) 413-98-14</a>-->
                        <v-img :src="developer.logo_url" class="developer-first-row__logo max-width300"></v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="text--white fz-18 mt-4">{{ developer.description_hy }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <waiting-call-dialog class="text-center" :btn_location="'developer'" />
                    </v-col>
                </v-row>
            </v-container>
        </v-img>
        <v-container fluid class="bg-f5f5f5">
            <v-container>
                <v-row>
                    <v-col xs="4" sm="12" md="9" lg="9" xl="9" cols="12" class="pa-0">
                        <open-street-map-multi-marker v-if="projects.length > 0" :lat="projects[0].latitude"
                            :lng="projects[0].longitude" :zoom="12" id="main-map" :markers="projects"
                            :height="first_map_height" />
                        <loading-grid v-if="isLoading" />
                        <v-row v-else class="text-center mt-3">
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="$vuetify.breakpoint.width < 900"
                                class="mt-11 mb-7">
                                <v-carousel :show-arrows="false" height="600" hide-delimiter-background
                                    :continuous="false" reverse-transition="fade-transition"
                                    transition="fade-transition" class="carousel-circle align-content-stretch d-flex">
                                    <v-carousel-item v-for="item in data_similar_offers_card"
                                        :key="`developer`+ item[0].id" :active-class="'active---class'">
                                        <similar-offers-card :project="item[0]" class="d-flex flex-column" />
                                        <similar-offers-card :project="item[1]" class="d-flex flex-column" />
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col v-else cols="12" sm="12" md="6" lg="4" xl="4" class="align-content-stretch d-flex"
                                v-for="item in projects.slice(0, 6)" :key="'similar-offers-card' + item.id">
                                <similar-offers-card :project="item" class="d-flex flex-column" />
                            </v-col>
                        </v-row>

                        <!-- Bank offers -->
                        <v-row class="mb-4">
                            <v-col>
                                <v-row>
                                    <v-col xs="10" sm="10" md="10" xl="10" lg="10" cols="12" offset-lg="1"
                                        offset-md="1">
                                        <v-subheader class="main_color_text text-h6 font-weight-bold pl-0">
                                            {{ $t('headers.purchase_methods') }}
                                        </v-subheader>
                                    </v-col>
                                </v-row>
                                <v-form>
                                    <v-row>
                                        <v-col class="d-flex flex-wrap justify-space-between flex-lg-nowrap" xs="10"
                                            sm="10" md="10" xl="10" lg="10" cols="12" offset-lg="1" offset-md="1">
                                            <v-text-field :label="$t('banks_filter.residential_complex')"
                                                class="main_color_text input_text mr-0 mr-lg-3 rounded-0 w-310" solo>
                                            </v-text-field>
                                            <v-select :label="$t('banks_filter.deadline')"
                                                class="color-icon-main input_text mr-0 mr-lg-3 rounded-0 max-width45"
                                                id="color-icon-main" append-icon="mdi-chevron-down" solo depressed
                                                :items="years">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="d-flex mt-n3 flex-wrap justify-space-between flex-lg-nowrap"
                                            xs="10" sm="10" md="10" xl="10" lg="10" cols="12" offset-md="1"
                                            offset-lg="1">
                                            <div class="wrap_inp max-width45">
                                                <span class="subtitle_input main_color_text"
                                                    v-text="$t('banks_filter.value')"></span>
                                                <v-text-field :label="$t('banks_filter.value')"
                                                    class="main_color_text input_text mr-0 mr-lg-3 rounded-0" solo>
                                                </v-text-field>
                                            </div>

                                            <div class="wrap_inp max-width45">
                                                <span class="subtitle_input main_color_text"
                                                    v-text="$t('headers.initial_fee')"></span>
                                                <v-text-field :label="$t('headers.initial_fee')"
                                                    class="main_color_text input_text mr-0 mr-lg-3 rounded-0" solo>
                                                </v-text-field>
                                            </div>

                                            <div class="wrap_inp w-310 mt-3 mt-lg-0">
                                                <span class="subtitle_input main_color_text"
                                                    v-text="$t('banks_filter.term')"></span>
                                                <v-text-field :value="slider_term"
                                                    class="main_color_text rounded-0 align-center slider_term_text_field"
                                                    hide-details :label="$t('banks_filter.term')" solo single-line
                                                    type="number">
                                                    <template v-slot:append>
                                                        <v-slider style="" :color="indigo"
                                                            class="slider_term main_color_text main_color w-310"
                                                            v-model="slider_term" :min="0" :max="30" hide-details>
                                                        </v-slider>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            class="d-flex justify-lg-end justify-space-between mt-6 mt-lg-0 align-center"
                                            xs="10" sm="10" md="10" xl="10" lg="10" cols="12" offset-md="1"
                                            offset-lg="1">
                                            <v-btn
                                                class="main_color_text transparent mr-1 text-capitalize subtitle-1 filter_clear_btn mr-2"
                                                depressed>{{ $t('headers.form_filter_btn_clear') }}
                                            </v-btn>
                                            <v-btn
                                                class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold"
                                                depressed>{{ $t('headers.form_filter_btn_apply') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                        <v-expansion-panels v-for="item in bank.slice(0, bank_qty)" :key="item.id">
                            <bank-offers-card :bank_data="item" />
                        </v-expansion-panels>
                        <v-row v-if="bank_qty <= bank.length">
                            <v-col class="d-flex justify-center mb-4">
                                <v-btn class="transparent text-transform-none main_color_text font-weight-bold fz-18"
                                    @click="bank_qty += 3" depressed>Ещё 3 предложения</v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <h3 class="main_color_text font-weight-bold mb-4 text-h5">{{ $t('headers.reviews') }}
                                </h3>
                                <div v-for="review in developer.reviews" :key="'review' + review.id">
                                    <comments-card :comment_data="review" />
                                </div>
                            </v-col>
                        </v-row>
                        <!-- Review -->
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="px-0 px-md-2 mb-6">
                                <h3 class="main_color_text font-weight-bold mb-4 text-h6">
                                    {{ $t('headers.leave_feedback') }}</h3>
                                <comment-form @update="updateDeveloper" :reviewable_type="'App\\Models\\Developer'"
                                    :reviewable_id="developer.id" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col md="3" class="pt-0 pr-0 pl-0 pl-md-4">
                        <div v-if="$vuetify.breakpoint.width > 900" class="mt-6 mt-md-0">
                            <aside-link-variable-card />
                        </div>

                        <advertising :limit="4" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Developer from "@/models/Developer";
import AsideLinkVariableCard from "@/components/cards/AsideLinkVariableCard";
import OpenStreetMapMultiMarker from "@/components/OpenStreetMapMultiMarker";
import LoadingGrid from "@/components/LoadingGrid";
import SimilarOffersCard from "@/components/cards/SimilarOffersCard";
import BankOffersCard from "../../../components/cards/BankOffersCard";
import Bank from "@/models/Bank";
import CommentsCard from "../../../components/cards/CommentsCard";
import CommentForm from "../../../components/forms/CommentForm";
import WaitingCallDialog from "@/components/dialogs/WaitingCallDialog";

export default {
    name: "developer.show",
    components: {
        FrontLayout,
        AsideLinkVariableCard,
        OpenStreetMapMultiMarker,
        LoadingGrid,
        SimilarOffersCard,
        BankOffersCard,
        CommentsCard,
        CommentForm,
        WaitingCallDialog,
        Advertising: () => import('@/components/ads/Advertising'),
    },
    data: function () {
        return {
            isLoading: false,
            developer: [],
            arr_developer_card_desktop: [],
            data_similar_offers_card: [],
            projects: [],
            first_map_height: 500,
            years: [
                this.$t('filterForm.turned_in'),
                new Date().getFullYear(),
                new Date().getFullYear() + 1,
                new Date().getFullYear() + 2,
                new Date().getFullYear() + 3,
                new Date().getFullYear() + 4,
                new Date().getFullYear() + 5,
            ],
            bank: [],
            bank_qty: 3,
            slider_term: 0,
            indigo: '#3F44C3',
            green: '#42B261',
            yellow: '#D6A700',
            developer_name:''
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.developer_title', {developer_name: this.developer_name}),
            meta: [
                { name: 'description', content: this.$t('meta.developer_description', {developer_name: this.developer_name}) }
            ]
        }
    },
    methods: {
        async init() {
            this.isLoading = true
            let slug = this.$route.params.developer
            if (slug) {
                this.developer = await Developer.custom('/developer/slug/' + slug).first()
                this.setDeveloperName()
                this.projects = this.developer.projects
                let arr_sale_card = this.projects.slice(0, 10)
                this.data_similar_offers_card = this.chunkArray(arr_sale_card, 2)
            }
            this.bank = await Bank.get()

            this.isLoading = false
        },
        setDeveloperName(){
            if(this.$i18n.locale === 'ru'){
                this.developer_name = this.developer.name_ru
            }else if(this.$i18n.locale === 'en'){
                this.developer_name = this.developer.name_en
            }else {
                this.developer_name = this.developer.name_hy
            }
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        first_map_height_set() {
            this.first_map_height = window.innerWidth > 1240 ? 500 : 200
        },
        updateDeveloper(review) {
            this.developer.reviews.push(review)
        }
    },
    mounted() {
        this.init()
        window.addEventListener("resize", this.first_map_height_set)
        this.first_map_height_set()
    }
}
</script>

<style lang="scss" scoped>
.developer-first-row {

    @media all and (max-width: 1240px) {
        flex-direction: column;
        justify-content: center;
        align-items: center !important;

        &__phone,
        &__logo {
            margin-bottom: 24px;
        }
    }
}

.min-width100 {
    min-width: 100%;
}

.max-width300 {
    max-width: 300px;
}

.btn-contact_us
{
padding: 15px 0;
background: #ffffff !important;
border: 2px solid var(--main_color-base);
border-radius: 0;
text-transform: none !important;
letter-spacing: 0;
font-weight: bold;
max-height: 49px;
width: 210px;
}

.fz-18 {
    font-size: 18px;
}

.fz-16 {
    font-size: 16px;
}

.fz-24 {
    font-size: 24px;
}

.text--white {
    color: white;
}

.bg_violet {
    background: #8e91d9 !important;
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.slider_term_text_field {
    position: relative;
    height: 48px;
    color: var(--main_color-base) !important;
}

.slider_term {
    color: var(--main_color-base) !important;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 350px;
}

.wrap_inp {
    position: relative;

    .subtitle_input {
        position: absolute;
        color: white;
        top: -25px;
        left: 0;
        font-size: 16px;
    }
}

.w-310 {
    width: 310px;

    @media all and (max-width: 1240px) {
        width: 100%;
        min-width: 100%;
    }
}

.max-width45 {
    @media all and (max-width: 1240px) {
        max-width: 45%;
    }
}

.sizing {
    box-sizing: border-box;
}
</style>
